.block-team {
  .employee {
    position: relative;

    .employee-image {
      object-position: top;
    }

    .employee-contacts {
      position: absolute;
      overflow: hidden;
      width: 0;
      height: 0;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: $primary;
      opacity: 0;
      transition: width 0.3s ease, height 0.3s ease, opacity 0.3s ease;

      i {
        font-size: 0;
        transition: font-size 0.4s ease;
      }
    }

    &:hover {
      .employee-contacts {
        width: 100%;
        height: 100%;
        opacity: 0.9;

        i {
          font-size: $h2-font-size;
        }
      }
    }
  }
}
