.navbar {
  background-color: $white;

  .navbar-toggler {
    border: none;

    &:hover,
    &:focus,
    &:active {
      border: none;
      box-shadow: none;
      -moz-box-shadow: none;
    }

    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%2811, 38, 101, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e")
    }
  }

  .navbar-collapse {
    .nav-item {
      position: relative;
      @include font-size($font-size-base * 1.2);

      @include media-breakpoint-down(lg) {
        @include font-size($font-size-base * 1.4);
      }

      a {
        color: $primary;
        border-top: 2px solid transparent;
        border-bottom: 2px solid transparent;
        padding-bottom: 0.2rem;
        padding-top: 0.2rem;
      }

      &:not(.dropdown) {
        a {
          &:hover {
            border-bottom: 2px solid $primary;
          }
        }
      }

      &.dropdown {
        .dropdown-menu {
          position: absolute;
          top: 48px;
          border-top: 2px solid #0B2665;
          border-radius: 0;
          min-width: 250px;

          li {
            &:active, &:focus, &:hover {
              background-color: $gray-100;
            }
          }

          .dropdpwn-item {
            &:active, &:focus, &:hover {
              background-color: $gray-100;
            }
          }
        }
      }
    }
  }

  .call-to-actions {
    .btn {
      @include font-size($font-size-base * 1.1);
    }
  }
}
