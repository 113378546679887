@font-face {
  font-family: 'EudoxusSans';
  src: url('/templates/_assets/fonts/EudoxusSans-Bold.woff2') format('woff2'),
  url('/templates/_assets/fonts/EudoxusSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'EudoxusSans';
  src: url('/templates/_assets/fonts/EudoxusSans-ExtraBold.woff2') format('woff2'),
  url('/templates/_assets/fonts/EudoxusSans-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'EudoxusSans';
  src: url('/templates/_assets/fonts/EudoxusSans-ExtraLight.woff2') format('woff2'),
  url('/templates/_assets/fonts/EudoxusSans-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'EudoxusSans';
  src: url('/templates/_assets/fonts/EudoxusSans-Light.woff2') format('woff2'),
  url('/templates/_assets/fonts/EudoxusSans-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'EudoxusSans';
  src: url('/templates/_assets/fonts/EudoxusSans-Medium.woff2') format('woff2'),
  url('/templates/_assets/fonts/EudoxusSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'EudoxusSans';
  src: url('/templates/_assets/fonts/EudoxusSans-Regular.woff2') format('woff2'),
  url('/templates/_assets/fonts/EudoxusSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
