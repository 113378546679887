footer {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='130' height='112' viewBox='0 0 130 112'%3E%3Cg id='Gruppe_568' data-name='Gruppe 568' transform='translate(-1790 -6296)'%3E%3Cg id='Gruppe_565' data-name='Gruppe 565' transform='translate(2318 5959) rotate(90)'%3E%3Crect id='Rechteck_273' data-name='Rechteck 273' width='31' height='28' transform='translate(418 500) rotate(180)' fill='%23fff'/%3E%3Crect id='Rechteck_275' data-name='Rechteck 275' width='31' height='28' transform='translate(418 426) rotate(180)' fill='%23fff'/%3E%3Crect id='Rechteck_287' data-name='Rechteck 287' width='31' height='28' transform='translate(449 528) rotate(180)' fill='%23fff'/%3E%3Crect id='Rechteck_274' data-name='Rechteck 274' width='50' height='46' transform='translate(387 472) rotate(180)' fill='%23fff'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");  background-position: top right;
  background-repeat: no-repeat;

  @include media-breakpoint-down(xxl) {
    background-size: 10%;
  }

  .sub-logo-text {
    max-width: 350px;
  }

  .social-icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
}