.block-gallery {
  .image-grid {
    img {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .lightbox-modal .modal-content {
    background: var(--lightbox);
  }

  .lightbox-modal .btn-close {
    position: absolute;
    top: 20px;
    right: 18px;
    font-size: 1.2rem;
    z-index: 10;
    opacity: 1;
  }

  .lightbox-modal .modal-body {
    display: flex;
    align-items: center;
    padding: 0;
    text-align: center;
  }

  .lightbox-modal img {
    width: auto;
    max-height: 100vh;
    max-width: 100%;
  }

  .lightbox-modal .carousel-caption {
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(36, 36, 36, 0.75);
  }

  .lightbox-modal .carousel-control-prev,
  .lightbox-modal .carousel-control-next {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    width: auto;
    opacity: 1;
  }

  .lightbox-modal .carousel-control-prev {
    left: 10px;
  }

  .lightbox-modal .carousel-control-next {
    right: 10px;
  }
}