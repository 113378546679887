.numbersAndFacts {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='131' height='177' viewBox='0 0 131 177'%3E%3Cg id='Gruppe_567' data-name='Gruppe 567' transform='translate(0 -4809)'%3E%3Crect id='Rechteck_282' data-name='Rechteck 282' width='28' height='28' transform='translate(103 4911) rotate(180)' fill='%23fff'/%3E%3Crect id='Rechteck_286' data-name='Rechteck 286' width='28' height='28' transform='translate(103 4837) rotate(180)' fill='%23fff'/%3E%3Crect id='Rechteck_283' data-name='Rechteck 283' width='28' height='28' transform='translate(131 4939) rotate(180)' fill='%23fff'/%3E%3Crect id='Rechteck_285' data-name='Rechteck 285' width='46' height='46' transform='translate(75 4883) rotate(180)' fill='%23fff'/%3E%3Crect id='Rechteck_284' data-name='Rechteck 284' width='75' height='75' transform='translate(75 4986) rotate(180)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
  background-position: bottom left;
  background-repeat: no-repeat;

  .fact-image {
    @include media-breakpoint-down(lg) {
      width: 150px;
    }
  }

  @include media-breakpoint-down(xl) {
    background-size: 6%;
  }

  @include media-breakpoint-down(lg) {
    background-size: 10%;
  }
}