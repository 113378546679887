.block-heading-text {
  display: flex;
  flex-direction: column;

  .text-content {
    @include media-breakpoint-up(md) {
      max-width: 600px;
    }
  }
}

