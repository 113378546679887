$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;

$white: #fff;
$gray-100: #f5f5f5;
$gray-200: #efefef;
$gray-300: #e5e5e5;
$gray-400: #d2d2d2;
$gray-500: #b0b0b0;
$gray-600: #797979;
$gray-700: #575757;
$gray-800: #3d3d3d;
$gray-900: #282828;
$black: #000;

$primary: #0B2665;
$secondary: #EFEFEF;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$light: $gray-100;
$dark: $gray-900;
$darker: #111;
$light-gray: #A8A8A8;
$lightest-gray: $gray-300;
