#hero {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='119' height='130' viewBox='0 0 119 130'%3E%3Cg id='Gruppe_536' data-name='Gruppe 536' transform='translate(119 250) rotate(180)'%3E%3Crect id='Rechteck_268' data-name='Rechteck 268' width='63' height='63' transform='translate(56 120)' fill='%23fff'/%3E%3Crect id='Rechteck_271' data-name='Rechteck 271' width='28' height='28' transform='translate(0 155)' fill='%23fff'/%3E%3Crect id='Rechteck_272' data-name='Rechteck 272' width='39' height='39' transform='translate(56 211)' fill='%23fff'/%3E%3Crect id='Rechteck_270' data-name='Rechteck 270' width='28' height='28' transform='translate(28 183)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");
  background-position: bottom left;
  background-repeat: no-repeat;

  @include media-breakpoint-down(xxl) {
    background-size: 6%;
  }

  .hero-image-wrapper {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: top left;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='91' height='130' viewBox='0 0 91 130'%3E%3Cg id='Gruppe_537' data-name='Gruppe 537' transform='translate(-120 119) rotate(-90)'%3E%3Crect id='Rechteck_268' data-name='Rechteck 268' width='63' height='63' transform='translate(56 120)' fill='%23022665'/%3E%3Crect id='Rechteck_272' data-name='Rechteck 272' width='39' height='39' transform='translate(-11 144)' fill='%23022665'/%3E%3Crect id='Rechteck_270' data-name='Rechteck 270' width='28' height='28' transform='translate(28 183)' fill='%23022665'/%3E%3C/g%3E%3C/svg%3E%0A");

      @include media-breakpoint-down(lg) {
        background-size: 10%;
      }
    }
  }

  .hero-heading {

    @include media-breakpoint-up(sm) {
      max-width: 540px;
    }

    @include media-breakpoint-up(md) {
      @include font-size($font-size-base * calc(30 / 16))
    }

    @include media-breakpoint-up(lg) {
      @include font-size($font-size-base * calc(24 / 16))
    }

    @include media-breakpoint-up(xl) {
      @include font-size($font-size-base * calc(30 / 16))
    }
  }

  .hero-text {
    @include media-breakpoint-up(xl) {
      @include font-size($font-size-base * 1.2)
    }
  }
}