// Utilities
$enable-negative-margins: true;

// Typography
$font-family-sans-serif: 'EudoxusSans', system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-root: 16px;
$font-size-base: 1rem;

$h1-font-size: $font-size-base * calc(72 / 16);
$h2-font-size: $font-size-base * calc(48 / 16);
$h3-font-size: $font-size-base * calc(32 / 16);
$h4-font-size: $font-size-base * calc(24 / 16);
$h5-font-size: $font-size-base;
$h6-font-size: $font-size-base * calc(16 / 16);

// Colors
$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "white": $white,
        "dark": $dark,
        "darker": $darker,
        "black": $black,
        "light-gray": $light-gray,
        "lightest-gray": $lightest-gray,
);

$spacer: 1rem;
$spacers: (
        0: 0,
        1: $spacer * .25,
        2: $spacer * .5,
        3: $spacer,
        4: $spacer * 1.5,
        5: $spacer * 3,
        6: $spacer * 4,
        7: $spacer * 5,
        8: $spacer * 7,
        9: $spacer * 9,
);

$aspect-ratios: (
        "1x1": 100%,
        "4x3": calc(3 / 4 * 100%),
        "16x9": calc(9 / 16 * 100%),
        "21x9": calc(9 / 21 * 100%),
        "18x6": calc(6 / 18 * 100%),
        "24x6": calc(6 / 24 * 100%),
        "10x14": calc(14 / 10 * 100%)
);

$enable-cssgrid: true;


$headings-font-family: 'EudoxusSans';
$headings-font-weight: 800;
$headings-color: $primary;

// Buttons
$btn-font-family: 'EudoxusSans';
$btn-font-weight: 700;
$btn-border-radius: 50px;
$btn-font-size: $font-size-base;
$btn-padding-x: 1.8rem;
$btn-padding-y: 0.8rem;

// Popovers
$popover-border-color: #8592B2;

// Dropdowns
$dropdown-link-hover-bg: $gray-100;
$dropdown-link-active-bg: $gray-100;