* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #FFFEFF;
  overflow-x: hidden;
  color: #1B222B;
}

a {
  &.no-link-style {
    @include link-unset;
  }
}

.max-width-content {
  margin-right: auto;
  margin-left: auto;
  max-width: $body-max-with;
}

.no-margin-bottom {
  :last-child {
    margin-bottom: 0 !important;
  }
}

a {
  color: $primary;
}

.upper-heading {
  &:hover {
    cursor: default;
  }
}

.btn-white {
  color: $primary;
}

.half-container {
  width: 100%;

  @include media-breakpoint-up(sm) {
    max-width: 540px;
  }

  @include media-breakpoint-up(md) {
    max-width: 720px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 640px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 660px;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 720px;
  }
}

.btn.no-hover-effect {
  &:hover {
    background-color: var(--bs-btn-bg);
    color: var(--bs-btn-color);
  }

  &:focus {
    background-color: var(--bs-btn-bg);
    color: var(--bs-btn-color);
  }

  &.active {
    &:hover {
      background-color: var(--bs-btn-active-bg);
      color: var(--bs-btn-active-color);
    }

    &:focus {
      background-color: var(--bs-btn-active-bg);
      color: var(--bs-btn-active-color);
    }
  }
}

.anchor {
  position: relative;
  top: -150px;
}

.base-layout {
  margin-top: 72px;

  @include media-breakpoint-up(xl) {
    margin-top: 115.59px;
  }
}