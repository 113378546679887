h1 {
  @extend %heading;
  @include font-size($h1-font-size);
  margin-bottom: 3rem;
  word-break: break-word;

  @include media-breakpoint-down(md) {
    margin-bottom: 2rem;
  }
}

h2 {
  @extend %heading;
  @include font-size($h2-font-size);
  margin-bottom: 2rem;
  word-break: break-word;

  @include media-breakpoint-down(md) {
    margin-bottom: 1.5rem;
  }
}

h3 {
  @extend %heading;
  @include font-size($h3-font-size);
  margin-bottom: 1.5rem;
  word-break: break-word;

  @include media-breakpoint-down(md) {
    margin-bottom: 1rem;
  }
}