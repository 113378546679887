#contact {
  .contact-icon-wrapper {
    width: 40px;
    height: 40px;
    border-radius: 50px;
  }
}

.block-google-map {
  min-height: 450px;

  .infowindows-wrapper {
    a {
      color: $primary;
    }
  }

  .gm-style-iw-t {
    bottom: 55px !important;
  }
}

.form-result {
  border: 2px solid $primary;
  padding: 1rem;
}

ul {
  &.errors {
    margin-top: 0.4rem;
    padding-left: 0;
    list-style: none;
    color: $red;
    @include font-size($font-size-base * 0.8)
  }
}